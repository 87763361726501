(function() { 'use strict';

  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 480;
  window.MOBILE_WIDTH = 800;
  window.TABLET_WIDTH = 950;
  window.SMALL_NOTEBOOK_WIDTH = 1200;
  window.NOTEBOOK_WIDTH = 1400;
  window.HEADER_HEIGHT = $('.header').height();

  // selectors
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);

  // tosrus default settings
  window.TOSRUS_DEFAULTS = {
    buttons: {
      next: true,
      prev: true
    },

    keys: {
      prev: 37,
      next: 39,
      close: 27
    },

    wrapper: {
      onClick: 'close'
    }
  };


  // Helpers
  // -------

  window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
  window.WINDOW_HEIGHT = $WINDOW.height();
  window.CONTENT_WIDTH = $('#structure').width();
  $WINDOW.resize(function() {
    WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
    WINDOW_HEIGHT = $WINDOW.height();
    CONTENT_WIDTH = $('#structure').width();
  });

  window.IS_DESKTOP_WIDTH = function() {
    return WINDOW_WIDTH > NOTEBOOK_WIDTH;
  }
  window.IS_NOTEBOOK_WIDTH = function() {
    return ( WINDOW_WIDTH > SMALL_NOTEBOOK_WIDTH && WINDOW_WIDTH <= NOTEBOOK_WIDTH );
  }
  window.IS_SMALL_NOTEBOOK_WIDTH = function() {
    return ( WINDOW_WIDTH > TABLET_WIDTH && WINDOW_WIDTH <= SMALL_NOTEBOOK_WIDTH );
  }
  window.IS_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH > MOBILE_WIDTH && WINDOW_WIDTH <= TABLET_WIDTH );
  }
  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH <= MOBILE_WIDTH;
  }
  window.IS_SMALL_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH <= SMALL_MOBILE_WIDTH;
  }


  // Masked input
  // ------------

  if (IS_DESKTOP) {
    $('input[type="date"]').attr('type', 'text');

    // Phone
    $('input[type="tel"]').mask('+7 (999) 999-99-99', {
      autoclear: false
    });

    // Date
    $('.js-date-mask').mask('99/99/9999', {
      placeholder: 'дд.мм.гггг',
      autoclear: false
    });

    // Time
    $('.js-time-mask').mask('99:99', {
      placeholder: 'чч:мм',
      autoclear: false
    });
  }

  // Overlay loader
  // --------------

  // open popup
  $DOCUMENT.on('click.overlay-open', '.js-overlay', function(e) {
    e.preventDefault();
    $.overlayLoader(true, {
      node: $($(this).attr('href')),
      hideSelector: '.js-popup-close',
      fixedElements: '.js-fixed-element'
    });
  });

  // open child popup
  $DOCUMENT.on('click.overlay-open', '.js-child-overlay', function(e) {
    var self = this;
    e.preventDefault();
    $.overlayLoader(false, {
      node: $(this).closest('.popup')
    });
    setTimeout(function() {
      $.overlayLoader(true, {
        node: $($(self).attr('href')),
        hideSelector: '.js-popup-close',
        fixedElements: '.js-fixed-element'
      });
    }, 250);
  });

  // open marathon popup
  $DOCUMENT.on('click.overlay-open', '.js-marathon-overlay', function(e) {
    e.preventDefault();
    var $popup = $($(this).attr('href'));
    $.overlayLoader(true, {
      node: $popup,
      hideSelector: '.js-popup-close',
      fixedElements: '.js-fixed-element',
      onShow: function() {
        $popup.closest('#uniloader-overlay').css('background', $popup.data('bg'));
        if ($popup.data('color')) {
          $popup.add($popup.find('.btn')).css('color', $popup.data('color'));
        }
      }
    });
  });

  // autofocus
  $DOCUMENT.on('overlayLoaderShow', function(e, $node) {
    $node.find('.js-autofocus-inp').focus();
  });

  // // close popup
  // $DOCUMENT.on('click.overlay-close', '.js-popup-close', function(e) {
  //   e.preventDefault();
  //   $.overlayLoader(false, $(this).closest('.js-popup'));
  // });


  // Selectric
  // ---------

  // init selectric
  $DOCUMENT.on('initSelectric yiiListViewUpdated', function() {
    $('select').selectric({
      disableOnMobile: true
    });
  }).trigger('initSelectric');


  // Scroll to
  // ---------

  $DOCUMENT.on('click.scroll-to', '.js-scroll-to', function(e) {
    e.preventDefault();

    var $lnk = $(this);
    var $elemToScroll = $($lnk.attr('href'));
    var speed = $lnk.data('speed') || 300;
    var offset = $lnk.data('offset') || 0;

    $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset - HEADER_HEIGHT});
  });


  // Menus
  // -----

  (function() { // Чтобы потом с тачем не запариваться
    var $menus = $('.js-menu');

    // if (IS_DESKTOP) {
    //   $menus.on('mouseenter.js-menu', 'li', function() {
    //     var self = $(this);
    //     clearTimeout(self.data('hoverTimeout'));
    //     self.addClass('is-hovered');
    //   });

    //   $menus.on('mouseleave.js-menu', 'li', function() {
    //     var self = $(this);
    //     self.data('hoverTimeout', setTimeout(function() {
    //       self.removeClass('is-hovered');
    //     }, 200));
    //   });
    // }

    // if (IS_MOBILE) {
      $menus.on('click.js-m-menu', 'a, span', function(e) {
        e.preventDefault();

        if ( $(e.target).hasClass('toggler') ) return;

        var $anchor = $(this);
        var $parent = $anchor.parent();

        var isWithDropdown = $parent.hasClass('has-dropdown') || $parent.hasClass('has-simple-dropdown');
        var isOnHover = $parent.hasClass('is-hovered');

        $parent.siblings().removeClass('is-hovered');

        if (!isWithDropdown) {
          if ($anchor.attr('href')) {
            if ($anchor.attr('target') == '_blank'){
              window.open($anchor.attr('href'), '_blank');
            } else {
              location.href = $anchor.attr('href');
            }
          }
        } else {
          if (isOnHover && $anchor.attr('href')) {
            // location.href = $anchor.attr('href');
            if ($anchor.attr('target') == '_blank'){
              window.open($anchor.attr('href'), '_blank');
            } else {
              location.href = $anchor.attr('href');
            }
          } else {
            $parent.toggleClass('is-hovered');
          }
        }
      });

      $menus.on('click.js-m-menu', '.toggler', function(e) {
        e.preventDefault();
        e.stopPropagation();

        $(this).closest('.has-dropdown').toggleClass('is-hovered');
      });
    // }

    $('body').click(function(e) {
      if ( !$(e.target).closest('.js-menu').length ) {
        $('.js-menu .is-hovered').removeClass('is-hovered');
      }
    });
  }());


  // Checkboxes
  // ----------

  $('body').on('change initCheckboxes', '.checkbox input', function() {
    var $inp = $(this),
        $label = $inp.closest('.checkbox');

    if ( $inp.prop('checked') ) {
      $label.addClass('checked');
    } else {
      $label.removeClass('checked');
    }
  });

  $('.checkbox input').trigger('initCheckboxes');


  // Radio buttons
  // ----------

  $('body').on('change initRadio', '.radio input', function() {
    var $inp = $(this),
        $group = $('[name="' + $inp.attr('name') + '"]'),
        $labels = $group.closest('.radio'),
        $selectedItem = $labels.find('input').filter(':checked').closest('.radio');

    $labels.removeClass('checked');
    $selectedItem.addClass('checked');
  });

  $('.radio input').trigger('initRadio');

  // // Toggle service groups
  // $('.form-service-toggler').change(function() {
  //   var $group = $('#' + $('.form-service-toggler').filter(':checked').data('group'));
  //   $group.stop(true, true).fadeIn().siblings().stop(true, true).hide();
  //   $('.checkbox input').trigger('initCheckboxes');
  //   $('.radio input').trigger('initRadio');
  // });


  // Tabs
  // ----

  $('.js-tabs .tabs-nav li a').click(function(e) {
    e.preventDefault();

    var $self = $(this);
    var $panel = $( $self.attr('href') );

    $self.closest('li').addClass('active').siblings().removeClass('active');
    $panel.closest('.tabs').find('.tabs-panel').hide();
    $panel.fadeIn();
  });


  // Galleries
  // ---------

  // init tosrus static gallery
  $('.js-gallery').each(function() {
    $(this).find('.js-gallery-item').tosrus(TOSRUS_DEFAULTS);
  });

  // init tosrus single image zoom
  $('.js-zoom').tosrus(TOSRUS_DEFAULTS);

  // mobile zoom
  if ( $WINDOW.width() <= SMALL_MOBILE_WIDTH ) {
    $('.js-mobile-zoom').tosrus(TOSRUS_DEFAULTS);
  }


  // Rotators
  // --------

  $('.js-slideshow').each(function() {
    var $self = $(this);

    var tos = $self.tosrus({
      effect: 'slide',
      slides: {
        visible: 1
      },
      autoplay: {
        play: true,
        timeout: 7500
      },
      infinite: true,
      pagination: {
        add: true
      }
    });
  });

  $('.js-text-slideshow').each(function() {
    var $self = $(this);

    var tos = $self.tosrus({
      effect: 'fade',
      slides: {
        visible: 1
      },
      autoplay: {
        play: true,
        timeout: 7500
      },
      infinite: true,
      pagination: {
        add: true
      }
    });
  });


  // Screen scrolling
  // ----------------

  /*
  var $screensForScrolling = $('.js-scroll-by-screen');
  if ( !IS_MOBILE_WIDTH() && $screensForScrolling.length ) {
    $screensForScrolling.each(function() {
      var self = $(this);
      self.bind('inview', function(event, isInView, visiblePartX, visiblePartY) {
        if (isInView) {
          // element is now visible in the viewport
          self.addClass('inview');
          if (visiblePartY == 'top') {
            // top part of element is visible
            self.removeClass('inview-bottom')
          } else if (visiblePartY == 'bottom') {
            // bottom part of element is visible
            self.addClass('inview-bottom')
          } else {
            // whole part of element is visible
            self.addClass('inview-bottom')
          }
        } else {
          // element has gone out of viewport
          self.removeClass('inview inview-bottom');
        }
      });
    });

    var scrollDuration = 700;

    var scrollOver = function(e) {
      // Перемотка до следующего блока по колесику мышки
      var $currentBlock = $('.js-scroll-by-screen.inview:last');

      if ( !$currentBlock.length ) return;

      var currentBlockTop = parseInt($currentBlock.offset().top, 10),
          currentBlockHeight = $currentBlock.height(),
          currentBlockBottom = parseInt(currentBlockTop + currentBlockHeight, 10),
          scrollTop = $WINDOW.scrollTop();

      if ( currentBlockTop + currentBlockHeight - HEADER_HEIGHT <= scrollTop ) return;

      if (e.originalEvent.deltaY > 0) {
        e.preventDefault();
        if ( currentBlockBottom > scrollTop + WINDOW_HEIGHT ) {
          $WINDOW.scrollTo(currentBlockBottom - WINDOW_HEIGHT, {axis: 'y', duration: scrollDuration});
        } else {
          $WINDOW.scrollTo(currentBlockBottom - HEADER_HEIGHT, {axis: 'y', duration: scrollDuration});
        }
      }
    }

    var scrollOverCycle = function(e) {
      scrollOver(e);
      $WINDOW.off('mousewheel.autoscroll');
      setTimeout(function() {
        $WINDOW.on('mousewheel.autoscroll', function(e) {
          scrollOverCycle(e);
        });
      }, scrollDuration);
    }

    $WINDOW.on('mousewheel.autoscroll', function(e) {
      scrollOverCycle(e);
    });
  }
  */


  // Scrolling to top
  // ----------------

  if ( !IS_MOBILE_WIDTH() ) {
    var goTopBtn = $('<div class="go-top-btn"></div>');
    goTopBtn.click(function() {
      $WINDOW.scrollTo(0, 200);
    });
    $WINDOW.scroll(function() {
      var scrollTop = $WINDOW.scrollTop();
      if ( scrollTop > 0 ) {
        goTopBtn.addClass('visible');
      } else {
        goTopBtn.removeClass('visible');
      }
    });
    $BODY.append( goTopBtn );
  }


  // Compare drag slider
  // -------------------

  $(function() {
    if ( !IS_SMALL_MOBILE_WIDTH() ) {
      var $compareSlider = $('.js-compare-slider'),
          isInline = $compareSlider.hasClass('compare-slider-inline'),
          // content_w = isInline ? $compareSlider.width() : CONTENT_WIDTH;
          content_w = $compareSlider.width();

      if ( isInline ) {
        $compareSlider.find('.compare-slider--bg').width( content_w );
      }

      $compareSlider.find('.js-compare-slider-handle').draggable({
        axis: 'x',
        containment: 'parent',
        drag: function(event, ui) {
          var pos = ui.position.left,
              opacity = pos / content_w;
          opacity = opacity - (1 - opacity) * .4;

          $('.compare-slider--left').width( pos )
            .find('.compare-slider--bg').css('opacity', opacity);
          $('.compare-slider--right').width( content_w - pos )
            .find('.compare-slider--bg').css('opacity', 1 - opacity);
        }
      });
    }
  });


  // Custom scrollbar
  // ----------------

  $('.js-scrollbar').perfectScrollbar();


  // Video placeholders
  // ------------------

  $('.js-video-placeholder').click(function(e) {
    if ( IS_MOBILE_WIDTH() ) return;

    e.preventDefault();

    var $self = $(this),
        src = $self.attr('href'),
        $video = $('<iframe frameborder="0" allowfullscreen></iframe>');

    $video.attr('src', src + '?autoplay=1&enablejsapi=1');
    if ($self.data('id')) {
      $video.attr('id', $self.data('id'));
    }
    $self.after( $video );
    $self.hide();
  });

  $(document).ready(function(){
    var ref = $.cookie('ref');
    ref = ref === null ? [document.referrer] : ref.split(',');
    ref.push(window.location.href);
    $.cookie('ref', ref, {expires: 1, path: '/'});
  });


  // Portfolio show animation

  $(function() {
    $('.portfolio-page').addClass('ready');
  });


  // Feedback link fix for pages without form

  $('.feedback-link').click(function() {
    if (!$('#form-feedback').length) {
      document.location.href = "/services/#feedback-link";
    }
  });


  // Contact page's floating subscribe form
  if ($('.contact-page').length > 0){

    var formSubscribe_y = $('.tab-throught').offset().top;
    var formSubscribe_x = $('.tab-throught').offset().left;
    $('.contact-panel--subscribe').css({"left":  formSubscribe_x, 'top': formSubscribe_y });

    $( window ).resize(function() {
      $('.tab-throught').each(function(){
        if ($(this).offset().top != 0){ formSubscribe_y = $(this).offset().top; }
        if ($(this).offset().left != 0){ formSubscribe_x = $(this).offset().left; }
      });
      $('.contact-panel--subscribe').css({"left":  formSubscribe_x, 'top': formSubscribe_y });
    });

    $('.js-tabs .tabs-nav li a').click(function(e) {
      setTimeout(function(){
        $('.contact-panel--subscribe').css({"left":  formSubscribe_x, 'top': formSubscribe_y });
      }, 250);
    });

  }


  // Callback and phone on mobiles
  // -----------------------------

  $('.js-show-contact-popup-on-mobile').click(function(e) {
    if (IS_SMALL_MOBILE_WIDTH()) {
      e.preventDefault();
      $.overlayLoader(true, {
        node: $('#mobile-phone-popup'),
        hideSelector: '.js-popup-close',
        fixedElements: '.js-fixed-element'
      });
    }
  });


  // Portfolio item pages
  // --------------------
  $('#expand-table').on('click', function(){
    $('.collapsible').slideToggle(700);
    $('#expand-table').hide();
  });


  // Spoiler works on seo page
  // ------------------
  $('.js-portfolio-spoiler').on('click', function(e){
    e.preventDefault();
    $('.js-hidden-works').show();
    $(this).hide();
  });


  // KVV
  // ----------
  $('#infographics-1-zoom').on('click', function(){
    $('#infographics-1').slideToggle(700);
    $WINDOW.scrollTo('#infographics-1', {duration: 300, offset: 0 - HEADER_HEIGHT});
  });
  $('#infographics-2-zoom').on('click', function(){
    if ($('#infographics-2').css('display') == 'none'){
      $('#infographics-2').slideToggle(700);
      $WINDOW.scrollTo('#infographics-2', {duration: 300, offset: 0 - HEADER_HEIGHT});
    } else {
      $('#infographics-2').slideToggle(700);
    }
  });


  // Green Planet
  // ------------
  if ($('#greenplanet-portfolio-page').length){

    var obj_1 = $('.js-parallax-img1');
    var obj_2 = $('.js-parallax-img2');
    var obj_2_1 = $('.js-parallax-img2 .parallax-img2-1');
    var obj_2_2 = $('.js-parallax-img2 .parallax-img2-2');

    var topParallax_1;
    var bottomParallax_1;
    var offset_1;

    var topParallax_2;
    var bottomParallax_2;
    var offset_2, offset_2_1, offset_2_2;

    var scrollTop;

    $WINDOW.on('resize', function(){
      topParallax_1 = obj_1.offset().top;
      bottomParallax_1 = topParallax_1 + obj_1.height();
      topParallax_2 = obj_2.offset().top;
      bottomParallax_2 = topParallax_2 + obj_2.height();
    });

    $WINDOW.on('scroll', function(){
      scrollTop = $(document).scrollTop();

      if ((scrollTop > topParallax_1) && (scrollTop < bottomParallax_1)){
        var offset_1 = scrollTop - topParallax_1;
        offset_1 = offset_1 / 3.75;
        obj_1.css('object-position', '0 ' + offset_1 + 'px');
      } else {
        if (scrollTop <= topParallax_1){
          obj_1.css('object-position', '0 0');
        }
      }

      if ((scrollTop > topParallax_2) && (scrollTop < bottomParallax_2)){
        var offset_2 = scrollTop - topParallax_2;
        offset_2_1 = offset_2 / 2.75;
        offset_2_2 = offset_2 / 4.25;
        obj_2_1.css('object-position', '0 ' + -offset_2_1 + 'px');
        obj_2_2.css('object-position', '0 ' +  offset_2_2 + 'px');
      } else {
        if (scrollTop <= topParallax_2){
          obj_2_1.css('object-position', '0 0');
          obj_2_2.css('object-position', '0 0');
        }
      }
    });

    $WINDOW.trigger('resize');
    $WINDOW.trigger('scroll');

    // пауза при воспроизведении видео
    $('.js-video-link video').on('click', function(){
      $(this).removeAttr('autoplay');
      $(this).removeAttr('loop');
    })
  }


  // Molbertbanket
  // -------------
  if ($('#molbertbanket-portfolio-page').length){

    var obj_2 = $('.js-parallax-img2');
    var obj_2_1 = $('.js-parallax-img2 .parallax-img2-1');
    var obj_2_2 = $('.js-parallax-img2 .parallax-img2-2');
    var topParallax_2;
    var bottomParallax_2;
    var offset_2, offset_2_1, offset_2_2;
    var scrollTop;

    $WINDOW.on('resize', function(){
      topParallax_2 = obj_2.offset().top;
      bottomParallax_2 = topParallax_2 + obj_2.height();
    });

    $WINDOW.on('scroll', function(){
      scrollTop = $(document).scrollTop();

      if ((scrollTop > topParallax_2) && (scrollTop < bottomParallax_2)){
        var offset_2 = scrollTop - topParallax_2;
        offset_2_1 = offset_2 / 2.75;
        offset_2_2 = offset_2 / 4.25;
        obj_2_1.css('object-position', '0 ' + -offset_2_1 + 'px');
        obj_2_2.css('object-position', '0 ' +  offset_2_2 + 'px');
      } else {
        if (scrollTop <= topParallax_2){
          obj_2_1.css('object-position', '0 0');
          obj_2_2.css('object-position', '0 0');
        }
      }
    });

    $WINDOW.trigger('resize');
    $WINDOW.trigger('scroll');
  }


  // File inputs
  // -----------------
  $('.js-file-inp input').change(function(e) {
    $(this).closest('.js-file-inp').find('.js-filename').text($(this).val());
  });


  // Textarea autosize
  // -----------------
  setTimeout(function() {
    autosize($('textarea'));
  }, 1000);


  // Tosrus popup video
  // ------------------
  $('.js-video-link').each(function() {
    $(this).tosrus( $.extend(TOSRUS_DEFAULTS, { wrapper: {classes: 'fullslide-video'} }) )
      .bind('opening.tos', function() {
        var $iframe = $(this).find('iframe'),
          src = $iframe.attr('src');

        $iframe.attr('src', src + '&autoplay=1');
      });
  });

  // Resize map on window resize
  // ---------------------------
  $WINDOW.on('resize', function(){
    var width = $WINDOW.width();
    $('.contact-section--map .ymaps-2-1-73-map').width( width );
  });

  var previousScrollPosition = $WINDOW.scrollTop();
  $WINDOW.on('scroll', function() {
    var newScrollPosition = $WINDOW.scrollTop();
    if (newScrollPosition > previousScrollPosition) {
      $('body').addClass('scrolled-down').removeClass('scrolled-up');
    } else {
      $('body').addClass('scrolled-up').removeClass('scrolled-down');
    }
    previousScrollPosition = newScrollPosition;
  });

  $('.js-video-popup').click(function(e) {
    e.preventDefault();
  
    $.overlayLoader(true, {
      node: $('#video-popup')
    });
  });

  $('.js-play-video').click(function(e) {
    e.preventDefault()
    const $this = $(this)
    const $videoLink = $this.prop('href');
    const $video = $('<iframe class="video__section" width="100%" height="100%" src="' + $videoLink + '" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
    $this.replaceWith($video);
  });

})();

